<template>

</template>
<script>
import {mapActions} from 'vuex'
import {USER_LOGGED_OUT} from '../constants/action-type'
import client from "../app/api/Client"

export default {
    methods: {
        ...mapActions([USER_LOGGED_OUT])
    },
    async mounted() {

        try {
            let data = await client().post('logout');
            await this[USER_LOGGED_OUT]();
            await this.$router.push({'name' : 'login'});
        } catch (e) {
        }
    }
}
</script>
